import { render, staticRenderFns } from "./ShopCarClassItem.vue?vue&type=template&id=11db1c5a&scoped=true&lang=pug&"
import script from "./ShopCarClassItem.vue?vue&type=script&lang=js&"
export * from "./ShopCarClassItem.vue?vue&type=script&lang=js&"
import style0 from "./ShopCarClassItem.vue?vue&type=style&index=0&id=11db1c5a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11db1c5a",
  null
  
)

export default component.exports