import { hasPermission, isSuperadmin, isOrganizationAdmin } from "@/helpers/permission"
import { PERMISSION_EDIT, PERMISSION_READ, PERMISSION_DENY } from "@/config/constants"
import { memoize } from "lodash-es"
import { bus } from "@/config"

const memoizedEdit = memoize(permissionType => {
  return hasPermission(permissionType, PERMISSION_EDIT)
})

const memoizedRead = memoize(permissionType => {
  return hasPermission(permissionType, PERMISSION_READ) || hasPermission(permissionType, PERMISSION_EDIT)
})

const memoizedDenyAll = memoize(permissionType => {
  return hasPermission(permissionType, PERMISSION_DENY)
})

const clearPermissionsCache = () => {
  memoizedEdit.cache.clear()
  memoizedRead.cache.clear()
  memoizedDenyAll.cache.clear()
}

export default {
  mounted() {
    bus.on("sign-out", clearPermissionsCache)
  },

  beforeDestroy() {
    bus.off("sign-out", clearPermissionsCache)
  },

  computed: {
    isSuperadmin,
    isOrganizationAdmin
  },

  methods: {
    hasPermission,

    hasEditPermission(permissionType = this.$route.meta.permissionType) {
      return memoizedEdit(permissionType)
    },

    hasReadPermission(permissionType = this.$route.meta.permissionType) {
      return memoizedRead(permissionType)
    },

    hasDenyPermission(permissionType = this.$route.meta.permissionType) {
      return memoizedDenyAll(permissionType)
    }
  }
}
